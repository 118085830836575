// Variable to store subpath abbreviation
export const subPathWithAbbreviation = [
  { abb: "뉴욕", name: "뉴욕" },
  { abb: "Boston_bigapple", name: "보스턴" },
  { abb: "Boston", name: "보스턴" },
  { abb: "LV_bigapple", name: "라스베가스/캐년" },
  { abb: "LV", name: "라스베가스/캐년" },
  { abb: "NF", name: "나이아가라" },
  { abb: "NF_bigapple", name: "나이아가라" },
  { abb: "샌프란", name: "샌프란시스코" },
  { abb: "LA", name: "Los Angeles" },
  { abb: "HL", name: "하와이/LA/샌디에고" },
];

export const subPathFromSubCategoryName = [
  { name: "뉴욕빅애플패스", subPath: "ba-pass" },
  { name: "뉴욕시티패스", subPath: "city-pass" },
  { name: "뉴욕익스플로러패스", subPath: "explore-pass" },
  { name: "Boston_bigapple", subPath: "ba-pass" },
  { name: "LV_bigapple", subPath: "ba-pass" },
  { name: "NF_bigapple", subPath: "ba-pass" },
  { name: "샌프란 빅애플패스", subPath: "ba-pass" },
  { name: "샌프란 시티패스", subPath: "city-pass" },
  { name: "샌프란 익스플로러패스", subPath: "explore-pass" },
  { name: "HLS_bigapple", subPath: "ba-pass" },

  { name: "Observation(Scenics)", subPath: "observations" },
  { name: "Museum/Gallery", subPath: "museum-gallery" },
  { name: "Rides/Cruises", subPath: "rides-cruises" },
  { name: "Activities", subPath: "activities" },

  { name: "Manhattan Day Tour", subPath: "manhattan-day" },
  { name: "Manhattan Night Tour", subPath: "manhattan-night" },
  { name: "Doson Tour", subPath: "doson-tour" },
  { name: "UN Tour", subPath: "un-tour" },
  { name: "My Page", subPath: "my-page" },
  { name: "Account", subPath: "account" },
];
